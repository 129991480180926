/**
 * @copyright Copyright 2021-2025 Epic Systems Corporation
 * @file BackgroundGalleryToggleRow
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.BackgroundGalleryToggleRow
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useContext, useEffect, useRef } from "react";
import { useStrings } from "~/hooks";
import { useLoadBackgroundProcessors } from "~/hooks/backgroundEffects";
import { alertActions } from "~/state";
import { useBackgroundProcessorsState } from "~/state/backgroundProcessors";
import { useCombinedSelectors } from "~/state/combined";
import { VideoSessionContext } from "~/web-core/components";
import BackgroundGallery from "./BackgroundGallery";

enum TokenNames {
	toggleRowLabel = "ToggleRowLabel",
	enableBackgroundEffects = "EnableBackgroundEffects",
	blurOnlyLabel = "BlurOnlyLabel",
	failedLoad = "FailedLoad",
}

export enum BackgroundGalleryToggleRowTestIds {
	label = "BackgroundGalleryToggleRowLabel",
}

/**
 * The BackgroundGalleryToggleRow component
 */
const BackgroundGalleryToggleRow: FC = () => {
	const { localDeviceStream = null } = useContext(VideoSessionContext);
	const dispatch = useDispatch();

	const isBackgroundsSupported = useCombinedSelectors(
		(selectors) => selectors.getIsBackgroundsSupported(),
		[],
	);

	const processorLoadStatus = useBackgroundProcessorsState(
		(selectors) => selectors.getProcessorLoadStatus(),
		[],
	);
	const loadProcessors = useLoadBackgroundProcessors();

	const strings = useStrings("BackgroundGalleryToggleRow", Object.values(TokenNames));
	const stringsRef = useRef(strings);

	// Load processors once we've received backgroundAccess from auth request
	useEffect(() => {
		if (isBackgroundsSupported && processorLoadStatus === "notStarted") {
			loadProcessors();
		}
	}, [loadProcessors, isBackgroundsSupported, processorLoadStatus]);

	// If processor load fails, close gallery and dispatch an alert
	useEffect(() => {
		if (processorLoadStatus === "failed") {
			dispatch(alertActions.postGenericAlert(stringsRef.current[TokenNames.failedLoad]));
		}
	}, [dispatch, processorLoadStatus]);

	return <BackgroundGallery applyStream={localDeviceStream} scrollGallery />;
};

BackgroundGalleryToggleRow.displayName = "BackgroundGalleryToggleRow";

export default BackgroundGalleryToggleRow;
