/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Implements virtual background initialization and management for Daily
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Vendor.Daily.Helpers.DailyBackgroundManager
 */

import { DailyCall, DailyEventObjectNonFatalError } from "@daily-co/daily-js";
import { BackgroundSettings } from "~/types/backgrounds";
import { ImageBackgroundOnLoad, VirtualBackgroundLoader } from "~/web-core/helpers/virtualBackgroundLoader";

export class DailyBackgroundManager extends VirtualBackgroundLoader {
	static backgroundInitializationDone: boolean = false;
	backgroundImageBytesMap: Map<string, ArrayBuffer>;
	private _testBackgroundCall: DailyCall;

	constructor(testBackgroundCall: DailyCall) {
		super();
		this.backgroundImageBytesMap = new Map<string, ArrayBuffer>();
		this._testBackgroundCall = testBackgroundCall;
	}

	/**
	 * Applies a test processor to the preview track to validate that backgrounds can load before displaying background options
	 * @returns Promise that resolves true if a background is able to successfully apply, false otherwise
	 */
	static async testApplyBackgroundProcessor(call: DailyCall): Promise<boolean> {
		const validateProcessorPromise = new Promise<boolean>((resolve) => {
			const removeBackgroundTestListeners = (): void => {
				call.off("input-settings-updated", onInputSettingsUpdated);
				call.off("nonfatal-error", onNonFatalError);
			};

			const onInputSettingsUpdated = (): void => {
				removeBackgroundTestListeners();
				resolve(true);
			};

			const onNonFatalError = (event: DailyEventObjectNonFatalError): void => {
				if (event.type === "input-settings-error") {
					removeBackgroundTestListeners();
					resolve(false);
				}
			};

			// Background application success event
			call.on("input-settings-updated", onInputSettingsUpdated);
			// Background application failure event
			call.on("nonfatal-error", onNonFatalError);
			void call.updateInputSettings({
				video: { processor: { type: "face-detection" } },
			});
		});

		const success = await validateProcessorPromise;

		void call.updateInputSettings({ video: { processor: { type: "none" } } });
		return success;
	}

	private imageProcessorInitialize: ImageBackgroundOnLoad = async (
		background: BackgroundSettings,
		_img: HTMLImageElement,
	): Promise<boolean> => {
		if (background.type !== "image") {
			return false;
		}
		try {
			const backgroundImageBytes = await fetch(background.src).then((response) =>
				response.arrayBuffer(),
			);
			this.backgroundImageBytesMap.set(background.path, backgroundImageBytes);
			return true;
		} catch (error) {
			return false;
		}
	};

	async initializeBackgroundProcessors(
		backgrounds: BackgroundSettings[],
		availableBackgroundsCount: number,
		mainCall: DailyCall,
	): Promise<void> {
		// In order for failures to be caught, the camera must be started before applying the processor
		await this._testBackgroundCall.startCamera();
		this._testBackgroundCall.setLocalVideo(true);

		// First, apply a test processor to the test call machine to validate the network request can succeed
		// If this fails, we don't want to try to apply a background to the main call, since it will trigger aggressive privacy handling
		// that will prevent the user's camera from enabling. This validates that the network request can succeed and does preloading so the preview processor can apply quickly
		const previewProcessorPreApplicationSucceeded =
			await DailyBackgroundManager.testApplyBackgroundProcessor(this._testBackgroundCall);
		if (!previewProcessorPreApplicationSucceeded) {
			this.emit("backgroundProcessorsDone", {
				type: "backgroundProcessorsDone",
				status: "failed",
				successfulProcessors: [],
			});
			return;
		}
		this._testBackgroundCall.setLocalVideo(false);

		// This should succeed (based on our test with the preview call), but do this pre-loading logic here so that processor application can happen more quickly
		const mainCallProcessorPreApplicationSucceeded =
			await DailyBackgroundManager.testApplyBackgroundProcessor(mainCall);
		if (!mainCallProcessorPreApplicationSucceeded) {
			this.emit("backgroundProcessorsDone", {
				type: "backgroundProcessorsDone",
				status: "failed",
				successfulProcessors: [],
			});
			return;
		}
		// Validate that the image processors can be reached
		await this.validateProcessors(
			backgrounds,
			availableBackgroundsCount,
			undefined,
			this.imageProcessorInitialize,
		);
		DailyBackgroundManager.backgroundInitializationDone = true;
	}
}
